import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { termsAndConditionsUK } from "../utilities/termsAndConditionsUK";

const TermsAndConditionsUk = ({ location }) => {
  return (
    <Layout location={location}>
      {/* Header */}
      <section>
        <div className="max-w-6xl px-4 mx-auto sm:px-6">
          <div className="pt-32 pb-4 md:pt-40 md:pb-4">
            {/* Section header */}
            <div className="max-w-3xl pb-12 mx-auto text-center md:pb-16">
              <h1 className="mb-4 h1">Service Agreement Terms & Conditions</h1>
              <h2 className="mb-4 font-semibold">
                Last updated: February 27, 2024
              </h2>
              <div className="italic">
                <div className="text-sm text-gray-400">
                  We have updated our online terms. If you are an Encord Client
                  that signed an Order Form or Statement of Work prior to
                  November 3, 2023, the version of the terms that were in effect
                  at that time govern your organization’s use of the Encord
                  Services.{" "}
                </div>

                <br />
                <br />
                <div>
                  You can find previous versions here:
                  <div className="italic text-purple-600">
                    <a
                      href="https://encord.com/terms-and-conditions-uk-november-2023/"
                      target="_blank"
                    >
                      November 7, 2023
                    </a>
                  </div>
                  <div className="italic text-purple-600">
                    <a
                      href="https://encord.com/terms-and-conditions-uk-march-2021/"
                      target="_blank"
                    >
                      March 20, 2021
                    </a>
                  </div>
                </div>
              </div>

              <p className="mt-4 text-xl text-gray-600">
                Welcome! These user terms of service (the{" "}
                <b>“Encord Client Terms”</b>
                or <b>“Terms”</b>) govern your access and use of the Cord
                Technologies Limited, a company incorporated in England and
                Wales under company number 12330483 having its registered
                address at 86-90 Paul Street, Third Floor, London, EC2A 4NE
                United Kingdom (“<b>Encord,</b>” “<b>we,</b>” or “<b>us</b>”)
                website, software, application, APIs, and any other services
                provided by us (collectively, the “<b>Services</b>”). Please
                read them carefully. Website visitors may be referred to in
                these Terms as “<b>Site Visitors</b>” and individuals or
                entities who use the Services or create an account (“
                <b>Account</b>”) and their Authorized Users are collectively
                referred to as “<b>Clients</b>”. Clients and Site Visitors may
                be referred to in these Terms as “users” “you” and “your” as
                applicable.
                <br />
                <br />
                In addition to these terms, we also publish a{" "}
                <a href="https://encord.com/privacy/" target="_blank">
                  <strong>Privacy Policy</strong>
                </a>{" "}
                that describes how we process, collect, use, and protect your
                information.
                <br />
                <br />
                These Terms are in addition to, and do not nullify, any other
                agreement between you and Encord or any other applicable terms
                and conditions provided through the use of the Services. If you
                are a paid customer (<strong>“Paid Customer”</strong>) and you
                or your organization are bound by a separate Services Agreement
                with Encord, then these Terms will apply, only to the extent
                that any terms here are not found in your Services Agreement.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*  Body of text  */}
      <section>
        <div className="max-w-5xl px-4 mx-auto sm:px-6">
          <div className="pb-12 md:pb-20 privacy-content">
            <ol className="max-w-3xl mx-auto">
              {termsAndConditionsUK.map(({ title, points }, index) => (
                <>
                  <li className="mb-3 h3" key={index}>
                    {title}
                    <ol className="max-w-3xl mx-auto mt-2">
                      {points.map((data, index) => (
                        <>
                          <li
                            key={index}
                            className="mb-8 text-lg font-normal text-gray-600"
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: data?.point,
                              }}
                            />
                            {data.sub_points && (
                              <ol className="max-w-3xl mx-auto mt-2">
                                {data?.sub_points?.map((point, index) => (
                                  <li
                                    key={`sub_${index}`}
                                    className="mb-8 text-lg font-normal text-gray-600"
                                    dangerouslySetInnerHTML={{
                                      __html: point,
                                    }}
                                  />
                                ))}
                              </ol>
                            )}
                          </li>
                        </>
                      ))}
                    </ol>
                  </li>
                </>
              ))}
            </ol>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TermsAndConditionsUk;

export const Head = () => (
  <SEO
    title="Service Agreement Terms & Conditions UK"
    description="Encord Terms of Business UK"
  />
);
